import API from '../repository';
import { EansVersionsImpl } from '@/models/Eans.model';

const RESOURCE = '/eans';

export default {
  async getAllVersions(): Promise<{ versions: EansVersionsImpl[] }> {
    return await API.get(`${RESOURCE}/versions`);
  },

  async updateDB(newEANS: number[]) {
    return await API.post(`${RESOURCE}/add`, {
      eans: newEANS,
    });
  },
};
