































import { Component, Vue } from 'vue-property-decorator';
import EansRepository from '@/repository/modules/eans.repository';
import { EansVersionsImpl } from '@/models/Eans.model';
import XLSX from 'xlsx';

@Component
export default class Eans extends Vue {
  private listVersions: EansVersionsImpl[] = [];
  loading = false;

  totalNewEansToInsert: number[] = [];
  disableButton = false;

  async mounted() {
    const versions = await EansRepository.getAllVersions();
    console.log(versions);
    this.listVersions = versions.versions;
  }

  get listadoInverso() {
    return this.listVersions?.reverse();
  }

  get lastVersion() {
    if (this.listadoInverso.length > 0) {
      return this.listadoInverso[0].version;
    } else {
      return 0;
    }
  }

  get totalEans() {
    if (this.listVersions.length > 0) {
      return this.listVersions.reduce((previus, current) => {
        const total = previus + current.total;
        return total;
      }, 0);
    } else {
      return 0;
    }
  }
  get totalSoldEans() {
    if (this.listVersions.length > 0) {
      return this.listVersions.reduce((previus, current) => {
        const total = previus + current.solds;
        return total;
      }, 0);
    } else {
      return 0;
    }
  }

  get totalLibres() {
    return this.totalEans - this.totalSoldEans;
  }

  async readFile(file: File) {
    if (file) {
      this.loading = true;
      this.totalNewEansToInsert = [];
      const binary = await this.readXLSX(file);
      /* read workbook */
      const wb = XLSX.read(binary, { type: 'binary' });
      /* grab first sheet */
      const wsname = wb.SheetNames[0]; //seleccionamos la hoja 1
      const ws = wb.Sheets[wsname]; // recojemos la tabla de la hoja 1
      const tablaToJson = XLSX.utils.sheet_to_json<number[]>(ws, { header: 1 });
      const listadoDeEansNuevos = tablaToJson.map(ean => ean[0]);
      await new Promise(done => setTimeout(() => done(''), 2000));
      this.loading = false;
      this.totalNewEansToInsert = listadoDeEansNuevos;
    }
  }

  async readXLSX(fileInput: File) {
    return new Promise(resolve => {
      const filReader = new FileReader();
      filReader.onload = (e: ProgressEvent<FileReader>) => {
        if (e !== null && e.target !== null) {
          const result = e.target.result as ArrayBuffer;
          let binary = '';
          const bytes = new Uint8Array(result);
          const length = bytes.byteLength;
          for (let i = 0; i < length; i++) {
            binary += String.fromCharCode(bytes[i]);
          }
          resolve(binary);
        }
      };
      filReader.readAsArrayBuffer(fileInput);
    });
  }

  async updateEANS() {
    EansRepository.updateDB(this.totalNewEansToInsert)
      .then(result => {
        this.disableButton = true;
      })
      .catch(e => {
        console.log('error', e);
      });
  }
}
